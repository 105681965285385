<template>
    <div class="admin-boxes">
        <div class="boxes-list">
            <div class="list-header">
                <div class="header-name">NAME</div>
                <div class="header-amount">AMOUNT</div>
                <div class="header-state">STATE</div>
                <div class="header-option">OPTION</div>
            </div>
            <div class="list-content">
                <transition name="fade" mode="out-in">
                    <div v-if="adminBoxList.data === null || adminBoxList.loading === true" class="content-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminBoxList.data.length > 0" class="content-list" key="data">

                        <AdminBoxesElement v-for="box in adminBoxList.data" v-bind:key="box._id" v-bind:box="box" />

                    </div>
                    <div v-else class="content-empty" key="empty">No boxes found.</div>
                </transition>
            </div>
            <div class="list-pagination">
                <button v-on:click="adminSetPage(adminBoxList.page - 1)" class="button-prev" v-bind:disabled="adminBoxList.page <= 1">
                    <div class="button-inner">
                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.2788 4.30337C11.2297 4.2954 11.18 4.29173 11.1302 4.29237H2.66447L2.84907 4.20651C3.02951 4.12111 3.19366 4.00487 3.33417 3.86307L5.70819 1.48906C6.02085 1.19059 6.07339 0.710444 5.83269 0.351417C5.55254 -0.0311676 5.0153 -0.114237 4.63269 0.165907C4.60178 0.188552 4.5724 0.213237 4.54479 0.2398L0.251817 4.53278C-0.0836794 4.8679 -0.0839745 5.41152 0.251146 5.74702C0.251361 5.74723 0.251602 5.74747 0.251817 5.74769L4.54479 10.0407C4.88056 10.3755 5.42418 10.3747 5.75903 10.039C5.78538 10.0125 5.80999 9.98443 5.83269 9.95481C6.07339 9.59578 6.02085 9.11564 5.70819 8.81717L3.33847 6.43886C3.21249 6.31275 3.06766 6.20701 2.90917 6.12547L2.65159 6.00956H11.083C11.5216 6.02585 11.9064 5.71946 11.9888 5.28834C12.0647 4.82027 11.7468 4.3793 11.2788 4.30337Z" />
                        </svg>
                    </div>
                </button>
                <div class="pagination-info">
                    PAGE <span class="text-green-gradient">{{adminBoxList.page}}</span> / {{Math.ceil(adminBoxList.count / 12) <= 0 ? '1' : Math.ceil(adminBoxList.count / 12)}}
                </div>
                <button v-on:click="adminSetPage(adminBoxList.page + 1)" class="button-next" v-bind:disabled="adminBoxList.page >= Math.ceil(adminBoxList.count / 12)">
                    <div class="button-inner">
                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.721245 4.30337C0.770346 4.2954 0.820037 4.29173 0.869755 4.29237H9.33553L9.15093 4.20651C8.97049 4.12111 8.80634 4.00487 8.66583 3.86307L6.29181 1.48906C5.97915 1.19059 5.92661 0.710444 6.16731 0.351417C6.44746 -0.0311676 6.9847 -0.114237 7.36731 0.165907C7.39822 0.188552 7.4276 0.213237 7.45521 0.2398L11.7482 4.53278C12.0837 4.8679 12.084 5.41152 11.7489 5.74702C11.7486 5.74723 11.7484 5.74747 11.7482 5.74769L7.45521 10.0407C7.11944 10.3755 6.57582 10.3747 6.24097 10.039C6.21462 10.0125 6.19001 9.98443 6.16731 9.95481C5.92661 9.59578 5.97915 9.11564 6.29181 8.81717L8.66153 6.43886C8.78751 6.31275 8.93234 6.20701 9.09083 6.12547L9.34841 6.00956H0.917005C0.478396 6.02585 0.0935841 5.71946 0.0111866 5.28834C-0.0647192 4.82027 0.253177 4.3793 0.721245 4.30337Z" />
                        </svg>
                    </div>
                </button>
            </div>
        </div>
        <div class="boxes-filters">
            <AdminFilterSearch />

            <div class="filters-create">
                <input v-model="adminName" type="text" placeholder="Enter name here..." />
                <input v-on:change="adminChangeImage" id="image" type="file" accept="image/*" />
                <input v-model="adminCategories" type="text" placeholder="Enter categories here..." />
                <div class="create-info">Categories: featured, low risk, 50/50, high risk and partners. Separate categories with comma.</div>
                
                <div class="create-items">
                    <div class="items-title">ITEMS</div>
                    <AdminItemElement v-for="(item, index) in adminItems" v-bind:key="index" v-bind:item="item" />
                </div>

                <AdminFilterItem />

                <button v-on:click="adminCreateButton()" class="button-create">CREATE BOX</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import AdminFilterSearch from '@/components/admin/AdminFilterSearch';
    import AdminFilterItem from '@/components/admin/AdminFilterItem';
    import AdminItemElement from '@/components/admin/AdminItemElement';
    import AdminBoxesElement from '@/components/admin/AdminBoxesElement';

    export default {
        name: 'AdminBoxes',
        components: {
            LoadingAnimation,
            AdminFilterSearch,
            AdminFilterItem,
            AdminBoxesElement,
            AdminItemElement
        },
        data() {
            return {
                adminName: null,
                adminImage: null,
                adminCategories: null,
                adminItems: [] 
            }
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'adminSetFilterSearch',
                'adminSetBoxListPage', 
                'adminGetBoxListSocket', 
                'adminSendBoxCreateSocket'
            ]),
            adminFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            adminFormatImage(image) {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.adminImage = event.target.result;
                }

                reader.readAsDataURL(image);
            },
            adminSetPage(page) {
                if(this.adminBoxList.page === page) { return; }
                if(page < 1 || page > Math.ceil(this.adminBoxList.count / 12)) { return; }

                this.adminSetBoxListPage(page);

                const data = { page: this.adminBoxList.page, search: this.adminFilterSearch };
                this.adminGetBoxListSocket(data);
            },
            adminChangeImage(e) {
                const image = e.target.files[0];
                this.adminFormatImage(image);
            },
            adminRemoveButton(item) {
                const index = this.adminItems.findIndex((element) => element.item._id === item.item._id);
                if(index !== -1) { this.adminItems.splice(index, 1); }
            },
            adminAddButton(item) {
                if(this.adminItems.some((element) => element.item._id === item._id) === true) {
                    this.notificationShow({ type: 'error', message: 'You can select items only once per box.' });
                    return;
                }

                this.adminItems.push({
                    item: item,
                    percentage: null
                });
            },
            adminCreateButton() {
                const items = this.adminItems.map((element) => ({ item: element.item._id, tickets: Math.floor(element.percentage * 1000) }));

                if(this.adminName === null || this.adminName.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered box name is invalid.' });
                    return;
                }

                if(items.length <= 0 || items.reduce((total, element) => total + element.tickets, 0) !== 100000) {
                    this.notificationShow({ type: 'error', message: 'Your entered items are invalid.' });
                    return;
                }

                const data = { name: this.adminName, image: this.adminImage, categories: this.adminCategories.split(','), items: items };
                console.log(data);
                this.adminSendBoxCreateSocket(data);
            }
        },
        computed: {
            ...mapGetters([
                'adminBoxList', 
                'adminFilterSearch'
            ])
        },
        created() {
            if(this.adminBoxList.loading === false) {
                const data = { page: this.adminBoxList.page, search: this.adminFilterSearch };
                this.adminGetBoxListSocket(data);
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSearch('');
            next();
        }
    }
</script>

<style scoped>
    .admin-boxes {
        width: 100%;
        display: flex;
    }

    .admin-boxes .boxes-list {
        width: calc(100% - 350px);
        padding-top: 15px;
        padding-right: 30px;
    }

    .admin-boxes .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 700;
        color: #c88bba;
    }

    .admin-boxes .header-name {
        width: 35%;
    }

    .admin-boxes .header-amount,
    .admin-boxes .header-state {
        width: 25%;
    }

    .admin-boxes .header-option {
        width: 15%;
        display: flex;
        justify-content: flex-end;
    }

    .admin-boxes .list-content {
        width: 100%;
        margin-top: 20px;
        padding: 20px 0;
        border-top: 1px solid rgba(109, 24, 79, 0.5);
        border-bottom: 1px solid rgba(109, 24, 95, 0.5);
    }

    .admin-boxes .content-loading {
        width: 100%;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-boxes .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-boxes .content-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-boxes .content-list {
        width: 100%;
    }

    .admin-boxes .content-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #8e5e84;
    }

    .admin-boxes .content-list.fade-enter-active,
    .admin-boxes .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-boxes .content-list.fade-enter-from,
    .admin-boxes .content-empty.fade-enter-from {
        opacity: 0;
    }

    .admin-boxes .list-pagination {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
    }

    .admin-boxes .list-pagination button {
        width: 52px;
        height: 37px;
        position: relative;
        padding: 1px;
        z-index: 1;
    }

    .admin-boxes .list-pagination button:disabled {
        cursor: not-allowed;
    }

    .admin-boxes .list-pagination button:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #fa01d1 0%, #b3019b 100%);
        clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    .admin-boxes .list-pagination button:disabled:before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #3e053b 100%);
    }

    .admin-boxes .list-pagination button:after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background: #2f0024;
        clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    .admin-boxes .list-pagination button .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(3, 20, 34, 0.27);
        clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
    }

    .admin-boxes .list-pagination button:disabled .button-inner {
        background: rgba(3, 20, 34, 0.27);
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
    }

    .admin-boxes .list-pagination button .button-inner svg {
        fill: #ff00fb;
    }

    .admin-boxes .list-pagination button:disabled .button-inner svg {
        fill: #ac7aa8;
    }

    .admin-boxes .pagination-info {
        font-size: 12px;
        font-weight: 800;
        color: #8e5e8c;
    }

    .admin-boxes .boxes-filters {
        width: 350px;
    }

    .admin-boxes .filters-create {
        width: 100%;
        margin-top: 20px;
    }

    .admin-boxes .filters-create input {
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        margin-top: 12px;
        padding: 0 15px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        background: rgba(88, 19, 85, 0.25);
        border: 1px dashed rgba(119, 46, 113, 0.5);
    }

    .admin-boxes .filters-create input[type="file"] {
        padding: 11.5px 15px;
        font-size: 14px;
        color: #7d4976;
    }

    .admin-boxes .filters-create input:first-child {
        margin-top: 0;
    }

    .admin-boxes .filters-create input::placeholder {
        color: #7d497c;
    }

    .admin-boxes .create-info {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #c88bc5;
    }

    .admin-boxes .create-items {
        margin-top: 15px;
    }

    .admin-boxes .items-title {
        font-size: 14px;
        font-weight: 700;
        color: #c88bc3;
    }

    .admin-boxes button.button-create {
        width: 100%;
        height: 47px;
        margin-top: 17px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
        background: linear-gradient(255deg, #ff00ea 0%, #aa007a 100%);
    }

    @media only screen and (max-width: 1250px) {

        .admin-boxes {
            flex-direction: column-reverse;
        }

        .admin-boxes .boxes-list {
            width: 100%;
            padding-top: 0;
            padding-right: 0;
        }

        .admin-boxes .list-header {
            display: none;
        }

        .admin-boxes .boxes-filters {
            width: 100%;
        }

    }
</style>